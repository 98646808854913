
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', {
        'id': 'search',
        'className': 'LayoutContentInner no-pad-t'
    }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && (!this.isFacetsSelected || this.notFilteredByVehicle) ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t contain any\n    ',
                !!this.vehicleString && !this.notFilteredByVehicle ? [
                    '\n      products that fit a ',
                    _createElement('span', { 'key': '28171' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString || this.notFilteredByVehicle ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '30991' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString && !this.notFilteredByVehicle ? [
                '\n    Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '32301'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && (!this.isFacetsSelected || !this.notFilteredByVehicle) ? ['\n      all keyword searches, filter selections, and vehicle selections to again browse all products.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3749'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3968'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4212'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '43630' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '44500' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '45330' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '43284' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.FilterChips(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel__horizontal cm_mobile-hide cm_FilterChips' }, [this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, _createElement('span', { 'className': 'discard' }, '×')));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, _createElement('span', { 'className': 'discard' }, '×'))) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'a',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, _createElement('span', { 'className': 'discard' }, '×'))) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'button btn btn-primary cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Start over\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [_createElement('div', {
            'className': 'Control accessoryButtons',
            'key': '134740'
        }, _createElement('a', {
            'className': 'btn green-bg white green-bg left p-l-30',
            'href': '/store/pg/16-Buyers-Guide.aspx'
        }, _createElement('div', { 'className': 'wetsuit-icon btn__inner' }, _createElement('div', { 'className': 'btn__inner-title' }, 'BUYERS GUIDE'))), _createElement('a', {
            'className': 'btn white blue-bg left p-l-20',
            'href': '/store/c/77-Wetsuit-Accessories.aspx'
        }, _createElement('div', { 'className': 'goggle-icon btn__inner' }, _createElement('div', { 'className': 'btn__inner-title' }, 'ACCESSORIES'))))], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'catTopNavArea clearfix m-b-10 pad-10 no-pad-lr dark2-bg white cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button btn btn-primary cm_dialogButton' }, this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '600'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8010'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null, ' FILTER RESULTS\n');
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', { 'className': 'cm_mobile-hide catShowByPage in-block v-mid pad-l-20 pad-r-20' }, _createElement('span', {}, 'Per Page'), '\n  ', this.pageSizeSelect, '\n'), _createElement('div', { 'className': 'catSortDD in-block v-mid pad-r-20' }, _createElement('span', { 'className': 'cm_mobile-hide' }, 'Sort By'), '\n  ', this.sortSelect, '\n'), _createElement('div', { 'className': 'cm_mobile-hide in-block v-mid pad-r-20' }, _createElement('span', {}, this.pageFrom, '-', this.pageTo, ' of ', this.totalHits)), _createElement('div', { 'className': 'cm_mobile-hide catSortAltView in-block v-mid pad-r-20' }, _createElement('strong', {}, 'VIEW:'), _createElement('a', {
                'onClick': this.setView('col3'),
                'className': 'altCatIcon aci3' + (this.view == 'col3' ? ' aciActive' : ''),
                'title': '3-Column'
            }), _createElement('a', {
                'onClick': this.setView('col2'),
                'className': 'altCatIcon aci2' + (this.view == 'col2' ? ' aciActive' : ''),
                'title': '2-Column'
            })), [this.pagination(function () {
                    function repeatButtons1(buttons, buttonsIndex) {
                        return [buttons(function () {
                                return this.template === 'prev' ? _createElement('li', { 'className': 'text cmTemplate_prev' }, _createElement('a', {
                                    'href': this.pageLink,
                                    'title': 'Prev'
                                }, _createElement('i', {
                                    'className': 'fa fa-angle-left',
                                    'aria-hidden': 'true'
                                }), _createElement('span', {}, ' Prev '))) : this.template === 'next' ? _createElement('li', { 'className': 'text cmTemplate_next' }, _createElement('a', {
                                    'href': this.pageLink,
                                    'title': 'Next'
                                }, _createElement('span', {}, ' Next '), _createElement('i', {
                                    'className': 'fa fa-angle-right',
                                    'aria-hidden': 'true'
                                }))) : this.template === 'delimiter' ? _createElement('li', { 'className': 'cmTemplate_delimiter' }, _createElement('span', {}, '\u2026')) : this.template === 'page' ? _createElement('li', { 'className': (this.isActive ? 'active' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('span', { 'key': '87' }, this.page) : null, !this.isActive ? _createElement('a', {
                                    'href': this.pageLink,
                                    'key': '140'
                                }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_mobile-hide in-block v-mid pad-r-20 push-right cm_pagination' }, _createElement('div', { 'className': 'cm_paging nav-paging-container clearfix no-pad dark2-bg' }, _createElement.apply(this, [
                        'ul',
                        { 'className': 'pagination push-right no-m dark2-bg cmRepeater_buttons' },
                        _map(this.buttons, repeatButtons1.bind(this))
                    ])));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function repeatSize1(size, sizeIndex) {
                            return _createElement('span', {
                                'className': 'product-size',
                                'key': size
                            }, size);
                        }
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function repeatI3(i, iIndex) {
                            return _createElement('td', { 'key': i }, this.average_review_rating > i ? _createElement('img', {
                                'src': '/images/star.png',
                                'key': '3878'
                            }) : null);
                        }
                        function repeatI4(i, iIndex) {
                            return _createElement('td', { 'key': i }, this.average_review_rating > i ? _createElement('img', {
                                'src': '/images/star.png',
                                'key': '5794'
                            }) : null);
                        }
                        return _createElement('div', { 'className': this.view === 'col3' ? 'col-md-4 col-xxs m-b-10 category-product grid-view alt-cat-view' : 'col-md-6 col-xxs m-b-30 category-product grid-view' }, this.view === 'col3' ? _createElement('div', {
                            'className': 'product-container',
                            'key': '192'
                        }, _createElement('div', { 'className': 'inner-container' }, _createElement('div', { 'className': 'cpdImage' }, _createElement('a', { 'href': this.url_rewrite }, _createElement('div', { 'className': 'altImgWrap' }, _createElement('img', {
                            'className': 'product-image',
                            'src': this.primary_picture,
                            'onError': this.onImageError
                        })), this.ClearanceDoNotDiscount ? _createElement('img', {
                            'className': 'cpdFlag',
                            'src': '/shared/Themes/PSFall2015PerfHeaderHP/images/clearance.png',
                            'alt': 'Clearance Item',
                            'data-isclearanceitem': 'True',
                            'key': '497'
                        }) : null, this.plussize ? _createElement('img', {
                            'className': 'plusSizeFlag',
                            'src': '/shared/Themes/PSFall2015PerfHeaderHP/images/plus_size.png',
                            'alt': 'Plus Size Available',
                            'data-isplusitem': 'True',
                            'key': '676'
                        }) : null), this.Size ? _createElement('div', {
                            'className': 'ProductDetailsAttributesTable CustomField m-t-10',
                            'key': '853'
                        }, _createElement('strong', {}, 'In Stock: '), _createElement.apply(this, [
                            'span',
                            { 'className': 'product-sizes' },
                            _map(this.Size, repeatSize1.bind(this))
                        ])) : null), _createElement('div', { 'className': 'catProdNamef' }, _createElement('a', mergeProps({
                            'href': this.url_rewrite,
                            'className': 'dark'
                        }, { dangerouslySetInnerHTML: { __html: this.item_name } }))), _createElement('div', { 'className': 'catProdDetails' }, _createElement('div', {}, _createElement('div', { 'className': 'catProdCustom row' }, _createElement('div', { 'className': 'col-xs-6 catProdFields no-pad-r' }, this.Thickness ? _createElement('table', {
                            'className': 'ProductDetailsAttributesTable',
                            'key': '1424'
                        }, _createElement('tbody', {}, _createElement('tr', {}, _createElement('td', { 'className': 'tdAttributeGroup ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesGroup ProductDetailsAttributesDiv' }, _createElement('strong', {}, 'Thickness: '))), _createElement('td', { 'className': 'ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesDiv' }, '\n                    ', this.Thickness.join(', '), '\n                  '))))) : null, this.Temperature ? _createElement('div', {
                            'className': 'ProductDetailsAttributesTable CustomField',
                            'key': '2132'
                        }, _createElement('strong', {}, 'Temp: '), _createElement('span', {}, this.Temperature)) : null, this.Seam_Type ? _createElement('table', {
                            'className': 'ProductDetailsAttributesTable',
                            'key': '2308'
                        }, _createElement('tbody', {}, _createElement('tr', {}, _createElement('td', { 'className': 'tdAttributeGroup ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesGroup ProductDetailsAttributesDiv' }, _createElement('strong', {}, 'Seam Type: '))), _createElement('td', { 'className': 'ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesDiv' }, '\n                    ', this.Seam_Type.join(', '), '\n                  '))))) : null, this.Extra ? _createElement('div', {
                            'className': 'ProductDetailsAttributesTable CustomField',
                            'key': '3016'
                        }, _createElement('strong', {}, 'Extra: '), _createElement('span', {}, this.Extra)) : null), _createElement('div', { 'className': 'col-xs-6 catProdFieldsPrice no-pad-l no-m-b' }, this.ProductVideoURL ? _createElement('div', {
                            'className': 'youtube-icon',
                            'data-videourl': this.ProductVideoURL,
                            'key': '3262'
                        }, _createElement('a', {
                            'className': 'ytLink',
                            'data-toggle': 'modal',
                            'data-target': '#youtubemodal',
                            'style': {
                                display: 'block',
                                marginBottom: '5px'
                            },
                            'href': this.ProductVideoURL
                        }, _createElement('img', { 'src': '/Shared/categoryAssets/youtube-preview-icon.png' }))) : null, this.average_review_rating ? _createElement('div', {
                            'className': 'acReviewWrap',
                            'key': '3615'
                        }, _createElement('table', {
                            'cellPadding': '0',
                            'cellSpacing': '0',
                            'className': 'StarRatings'
                        }, _createElement('tbody', {}, _createElement.apply(this, [
                            'tr',
                            {},
                            _map([
                                0,
                                1,
                                2,
                                3,
                                4
                            ], repeatI3.bind(this))
                        ])))) : null, _createElement('hr', { 'className': 'reviewLine' }), this.price ? _createElement('div', {
                            'className': 'catAltPrice price',
                            'key': '4102'
                        }, _createElement('span', { 'className': 'CategoryProductPriceArea' }, _createElement('span', { 'className': 'CategoryProductPrice' }, this.formatPrice(this.price), '\xA0'))) : null, this.retail ? _createElement('div', {
                            'className': 'strike gray was-price',
                            'key': '4345'
                        }, this.formatPrice(this.retail)) : null))), _createElement('div', { 'className': 'clearfix' })), _createElement('div', { 'className': 'clearfix' }))) : null, this.view === 'col2' ? _createElement('div', {
                            'className': 'product-container',
                            'key': '4556'
                        }, _createElement('div', { 'className': 'cat-header ltgray-bg' }), _createElement('div', { 'className': 'inner-container' }, _createElement('div', { 'className': 'catProdNamef' }, _createElement('a', mergeProps({
                            'href': this.url_rewrite,
                            'className': 'dark'
                        }, { dangerouslySetInnerHTML: { __html: this.item_name } }))), _createElement('div', { 'className': 'catProdDetails' }, _createElement('div', { 'className': 'col-sm-5 cpdImage' }, _createElement('a', { 'href': this.url_rewrite }, _createElement('img', {
                            'className': 'product-image',
                            'src': this.primary_picture,
                            'onError': this.onImageError,
                            'alt': this.removeHTML(this.name)
                        }))), _createElement('div', { 'className': 'col-sm-7' }, this.price ? _createElement('span', {
                            'className': 'red price',
                            'key': '5131'
                        }, 'Price: ', this.formatPrice(this.price)) : null, _createElement('br', {}), this.retail ? _createElement('span', {
                            'className': 'strike gray was-price',
                            'key': '5230'
                        }, this.formatPrice(this.retail)) : null, _createElement('span', { 'className': 'red save' }, 'SAVE '), _createElement('br', {}), _createElement('span', { 'className': 'green status' }, 'In Stock'), _createElement('br', {}), _createElement('a', {
                            'className': 'ad-btn orange-bg white',
                            'href': this.url_rewrite
                        }, 'SHOP NOW'), _createElement('hr', {}), this.average_review_rating ? _createElement('span', {
                            'className': 'reviewTitle',
                            'style': { display: 'inline' },
                            'key': '5523'
                        }, 'Rating:') : null, _createElement('table', {
                            'cellPadding': '0',
                            'cellSpacing': '0',
                            'className': 'StarRatings'
                        }, _createElement('tbody', {}, _createElement.apply(this, [
                            'tr',
                            {},
                            _map([
                                0,
                                1,
                                2,
                                3,
                                4
                            ], repeatI4.bind(this))
                        ]))), _createElement('hr', {
                            'className': 'reviewLine',
                            'style': { display: 'block' }
                        }), _createElement('div', { 'className': 'catProdCustom' }, _createElement('div', { 'className': 'no-pad catProdFields' }, this.Thickness ? _createElement('table', {
                            'className': 'ProductDetailsAttributesTable',
                            'key': '6073'
                        }, _createElement('tbody', {}, _createElement('tr', {}, _createElement('td', { 'className': 'tdAttributeGroup ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesGroup ProductDetailsAttributesDiv' }, _createElement('strong', {}, 'Thickness:'))), _createElement('td', { 'className': 'ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesDiv' }, '\n                    ', this.Thickness.join(', '), '\n                  '))))) : null, this.Temperature ? _createElement('div', {
                            'className': 'ProductDetailsAttributesTable CustomField',
                            'key': '6780'
                        }, _createElement('strong', {}, 'Temp:'), _createElement('span', {}, this.Temperature)) : null, this.Seam_Type ? _createElement('table', {
                            'className': 'ProductDetailsAttributesTable',
                            'key': '6954'
                        }, _createElement('tbody', {}, _createElement('tr', {}, _createElement('td', { 'className': 'tdAttributeGroup ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesGroup ProductDetailsAttributesDiv' }, _createElement('strong', {}, 'Seam Type:'))), _createElement('td', { 'className': 'ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesDiv' }, '\n                    ', this.Seam_Type.join(', '), '\n                  '))))) : null, this.Length ? _createElement('table', {
                            'className': 'ProductDetailsAttributesTable',
                            'key': '7661'
                        }, _createElement('tbody', {}, _createElement('tr', {}, _createElement('td', { 'className': 'tdAttributeGroup ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesGroup ProductDetailsAttributesDiv' }, _createElement('strong', {}, 'Length:'))), _createElement('td', { 'className': 'ProductDetailsAttributesTD' }, _createElement('div', { 'className': 'ProductDetailsAttributes ProductDetailsAttributesDiv' }, '\n                    ', this.Length.join(', '), '\n                  '))))) : null, this.Extra ? _createElement('div', {
                            'className': 'ProductDetailsAttributesTable CustomField m-t-10',
                            'key': '8359'
                        }, _createElement('strong', {}, 'Extra:'), _createElement('span', {}, this.Extra)) : null, this.Size ? _createElement('div', {
                            'className': 'ProductDetailsAttributesTable CustomField m-t-10',
                            'key': '8529'
                        }, _createElement('strong', {}, 'Sizes in Stock:'), _createElement('span', {}, this.Size.join(', '))) : null), _createElement('div', { 'className': 'no-pad hidden-xs' }, this.ProductVideoURL ? _createElement('div', {
                            'className': 'youtube-icon',
                            'data-videourl': this.ProductVideoURL,
                            'src': '/Shared/categoryAssets/youtube-preview.png',
                            'style': { display: 'block' },
                            'key': '8781'
                        }, _createElement('a', {
                            'className': 'ytLink',
                            'data-toggle': 'modal',
                            'data-target': '#youtubemodal',
                            'style': {
                                borderTop: '1px solid #eee',
                                paddingTop: '15px',
                                display: 'block'
                            },
                            'href': this.ProductVideoURL
                        }, _createElement('img', { 'src': '/Shared/categoryAssets/youtube-preview.png' }))) : null))), _createElement('div', { 'className': 'clearfix' })), _createElement('div', { 'className': 'clearfix' }))) : null);
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'product-list cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': 'items-container' + (this.view === 'col3' ? ' row col3 altViewRow' : ' row col2 cm_loading-relative') + ' cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default button btn btn-primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        Load more\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('li', { 'className': 'text cmTemplate_prev' }, _createElement('a', {
                            'href': this.pageLink,
                            'title': 'Prev'
                        }, _createElement('i', {
                            'className': 'fa fa-angle-left',
                            'aria-hidden': 'true'
                        }), _createElement('span', {}, ' Prev '))) : this.template === 'next' ? _createElement('li', { 'className': 'text cmTemplate_next' }, _createElement('a', {
                            'href': this.pageLink,
                            'title': 'Next'
                        }, _createElement('span', {}, ' Next '), _createElement('i', {
                            'className': 'fa fa-angle-right',
                            'aria-hidden': 'true'
                        }))) : this.template === 'delimiter' ? _createElement('li', { 'className': 'cmTemplate_delimiter' }, _createElement('span', {}, '\u2026')) : this.template === 'page' ? _createElement('li', { 'className': (this.isActive ? 'active' : '') + ' cmTemplate_page' }, this.isActive ? _createElement('span', { 'key': '87' }, this.page) : null, !this.isActive ? _createElement('a', {
                            'href': this.pageLink,
                            'key': '140'
                        }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_mobile-hide cm_pagination' }, _createElement('div', { 'className': 'cm_paging nav-paging-container clearfix no-pad dark2-bg' }, _createElement.apply(this, [
                'ul',
                { 'className': 'pagination push-right no-m dark2-bg cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ])));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:message","cm:customMessage","cm:filterChips","cm:FilterChips","cm:dialogButton","cm:facetDialogButton","cm:pagination","cm:SearchHeader","cm:loadMoreButton","cm:loadMore","cm:SearchResult","cm:pagination"]