import _findKey from 'lodash-es/findKey.js';

import pageType from 'Addons/search/pageType.ts';
import { mainConfig } from 'Models/uiConfig/componentlessWidgetsConfig.ts';
import defaultUiConfig from 'Stores/_default-store/uiConfig.js';
import { trimEndSlash } from 'Utils/string.js';

const {
  AfterInit: rawAfterInit,
  collapsibleAnimationTime = 500,
  colorGroups,
  colorStripes,
  CookieStorage,
  customFacetValueFilter,
  customMessage = {},
  customUriParser = {},
  defaultView = '',
  deferInit,
  disableRequestScroll,
  FacetsCollapsedDefault,
  groupColorsBy,
  joinedChips,
  noResultsRedirectTimeout = 0,
  page = {},
  pagination: rawPaginationSettings = {},
  platform,
  scrollDetection,
  SearchPageBaseUrl = '',
  searchPageUrl: customSearchPageUrl,
  stickyHeaderSelector,
  useLocationHash,
  Widgets,
} = mainConfig;

const {
  page: pageDefaults = {},
  pageNumberParamName = 'page',
  searchPageUrl: defaultSearchPageUrl,
} = defaultUiConfig || {};

function afterInit() {
  if (rawAfterInit) {
    try {
      rawAfterInit();
    } catch (e) {
      console.warn(e);
    }
  }
}

const searchPageUrl = customSearchPageUrl || defaultSearchPageUrl;

const uiConfig = {
  afterInit,
  collapsibleAnimationTime,
  colorPaletteConfig: { colorGroups, colorStripes, groupColorsBy },
  cookies: { use: CookieStorage },
  customFacetValueFilter,
  customMessage,
  customUriParser: { parsingNeeded: () => false, ...customUriParser },
  defaultView,
  deferInit,
  enableRequestScroll: !disableRequestScroll,
  facetsCollapsedDefault: FacetsCollapsedDefault,
  joinedChips,
  noResultsRedirectTimeout,
  pageNumberParamName,
  pageType: getPageType(),
  pagination: { edgeRange: 1, centralRange: 2, ...rawPaginationSettings },
  platform,
  scrollDetection,
  searchPageBaseUrl: SearchPageBaseUrl,
  searchPageUrl,
  stickyHeaderSelector,
  useLocationHash,
  widgetsConsideredInScroll: Widgets.filter((w) => w.consideredInScroll),
};

window.Convermax.templates.config.uiConfig = uiConfig;
export default uiConfig;

function getPageType() {
  if (trimEndSlash(window.location.pathname) === trimEndSlash(searchPageUrl)) {
    return pageType.search;
  }

  const getType = page.getPageType || pageDefaults.getPageType;
  const type = getType?.(pageDefaults.getPageType?.defaults ?? { getter: () => pageType.unknown });

  if (type && type !== pageType.unknown) {
    return type;
  }

  return (
    _findKey({ ...(pageDefaults.typeToSelectorMap || {}), ...page.typeToSelectorMap }, (selector) =>
      window.document.querySelector(selector),
    ) || pageType.unknown
  );
}
